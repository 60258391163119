import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const CustomizeGeneral = () => {
  return (
    <LayoutComponent>
      <h2>Customize General Configurations</h2>
      <ol>
        <li>
          Select one of the following configuration options:{" "}
          <strong>Environments</strong>, <strong>Labels</strong>,{" "}
          <strong>Iterations</strong>, or <strong>Modules</strong>
        </li>
        <li>
          In the selected configuration section, enter a name for the new entry.
        </li>
        <li>
          Once you've entered the necessary information,click on{" "}
          <strong>Add New</strong> to create a new entry.
        </li>
        <div className="testimonials">
          <h5>Tip</h5>
          <p>
            You can optionally add a description when creating a new{" "}
            <strong>Environment</strong>, <strong>Module</strong>, or{" "}
            <strong>Iteration</strong>. This can help provide additional context
            or details.
          </p>
        </div>
        <li>
          Your new configuration entry will appear in the list below. You can
          edit or delete these entries as needed.
        </li>
      </ol>
    </LayoutComponent>
  )
}

export default CustomizeGeneral
